<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="user-management__header-container">
        <span class="user-management__header-title">
          Gerenciar Perfis
        </span>
        <span @click="closeDialog" class="material-icons closebtn-icon">
          cancel
        </span>
      </div>
      <div class="permission-modal__main-container">
        <div class="permission-modal__content-container">
          <div class="permission-modal__left-container">
            <div class="input-title">
              Perfis
            </div>
            <div class="input-container">
              <input type="text" v-model="new_profile.name" class="input-value" placeholder="Adicione um novo perfil">
              <span class="material-icons input-icon" @click="new_profile.name && save(new_profile)">
                add_circle_outline
              </span>
            </div>
            <div class="profiles-wrapper">
              <cc-loader v-show="loading"></cc-loader>
              <div v-show="!loading" class="page-table-container">
                <div class="page-table-content">
                  <div class="page-table-header">
                    <div class="page-table-header-text id-width">#ID</div>
                    <div class="page-table-header-text input-width">Nome</div>
                    <div class="page-table-header-text actions-width">Ações</div>
                  </div>
                  <div v-for="profile in profiles" :key="'prof-' + profile.id" @click.stop="select_profile(profile)"
                    :class="{ selected: profile.selected }">
                    <div class="page-table-row">
                      <div class="page-table-desc-column id-width">
                        <b>#{{ profile.id }}</b>
                      </div>
                      <div class="page-table-desc-column input-width">
                        <cc-input v-model="profile.name" :disabled="!profile.selected" />
                      </div>
                      <div class="page-table-desc-column actions-width">
                        <span v-if="profile.selected" class="material-icons-outlined save-icon" @click="save(profile)">
                          save
                        </span>
                        <span v-else class="material-icons-outlined edit-icon">
                          edit
                        </span>
                        <span v-show="!profile.loading" class="material-icons-outlined remove-icon"
                          @click.stop="remove_profile(profile)">
                          delete
                        </span>
                        <cc-loader v-show="profile.loading" :show_txt="false" class="loader float-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="current_profile.id" class="permission-modal__content-container">
          <div class="permission-modal__middle-container">
            <div class="input-title">
              {{ current_profile.name }}
            </div>
            <div class="profiles-wrapper">
              <cc-loader v-show="loading"></cc-loader>
              <div v-show="!loading">
                <div>
                  <div class="page-table-header">
                    <div class="page-table-header-text checkbox-width"></div>
                    <div class="page-table-header-text modules-width">Módulo</div>
                  </div>
                  <div v-for="mod in current_modules" :key="'perm-res-' + mod.name">
                    <div @click="current_module = mod" class="page-table-row">
                      <div class="page-table-desc-column checkbox-width">
                        <cc-checkbox
                          @change="(evt) => evt.target.checked ? save_permission(mod) : remove_permission(mod)"
                          :checked="has_permission(mod)" class="checkbox-color" />
                      </div>
                      <div class="page-table-desc-column modules-width">
                        {{ mod.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="current_profile.id" class="permission-modal__content-container">
          <div class="permission-modal__right-container">
            <div class="input-title">
              {{ current_module.name }}
            </div>
            <div class="profiles-wrapper">
              <cc-loader v-show="loading"></cc-loader>
              <div v-show="!loading">
                <div>
                  <div class="page-table-header">
                    <div class="page-table-header-text check-circle-width"></div>
                    <div class="page-table-header-text text-width">Permissão</div>
                  </div>
                  <div v-for="(perm, idx) in current_module.permissions"
                    :key="'perm-' + perm.resource + '-act-' + perm.action"
                    @click="has_permission(perm) ? remove_permission(perm) : save_permission(perm)">
                    <div :class="getSpecialBackground(idx)" class="page-table-row ">
                      <div class="page-table-desc-column success-update check-circle-width">
                        <span class="material-icons-outlined" :class="{ visible: has_permission(perm) }">
                          check_circle
                        </span>
                      </div>
                      <div class="page-table-desc-column text-width">
                        <p>{{ perm.name }}</p>
                        <small>{{ perm.description }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cc-loader-full v-if="loading2" />
  </div>
  <!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4">
				<div class="col-4">
					<label for="" class="label-control">Perfis</label>
					<div class="col-12 mb-3">
						<div class="row">
							<div class="col p-0">
								<cc-search :placeholder="'Novo Perfil'"
                                    :icon="'fa success fa-plus'"
                                    :theme="'theme-green'"
                                    class="mt-1"
                                    v-model="new_profile.name"
                                    @search="() => new_profile.name && save(new_profile)" />
							</div>
						</div>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">#ID</th>
									<th class="text-left px-0">Nome</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="profile in profiles"
                                    :key="'prof-' + profile.id"
                                    @click.stop="select_profile(profile)"
                                    :class="{ selected: profile.selected }">
									<td class="text-left px-3">
                                        <i v-show="profile.selected" class="fas fa-hand-point-right mr-2 theme-blue"></i><b>#{{ profile.id }}</b>
                                    </td>
									<td class="text-left px-0">
										<cc-input v-model="profile.name" :disabled="!profile.selected" />
									</td>
									<td class="text-center">
										<i class="fa fa-floppy-o remove-btn theme-green" @click="save(profile)" />
										<i v-show="!profile.loading" class="fa fa-trash remove-btn" @click.stop="remove_profile(profile)" />
										<cc-loader v-show="profile.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-4" v-if="current_profile.id">
					<label for="" class="label-control">{{ current_profile.name }}</label>
					<div class="sellers-list list-group">
						<cc-loader v-show="loading"></cc-loader>
						<table class="table" v-show="!loading">
							<thead>
								<tr>
                                    <th></th>
									<th class="text-left px-3">Módulo</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="mod in current_modules" :key="'perm-res-' + mod.name">
                                    <td>
                                        <div class="checkbox">
                                            <cc-checkbox
                                                @change="(evt) => evt.target.checked ? save_permission(mod) : remove_permission(mod)"
                                                :checked="has_permission(mod)" />
                                        </div>
                                    </td>
									<td class="text-left px-3" @click="current_module = mod">
                                        {{ mod.name }}
                                    </td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
                <div class="col-4"  v-show="current_profile.id">
					<label for="" class="label-control">{{ current_module.name }}</label>
					<div class="sellers-list list-group">
						<cc-loader v-show="loading"></cc-loader>
						<table class="table" v-show="!loading">
							<thead>
								<tr>
                                    <th></th>
									<th class="text-left px-3">Permissão</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="perm in current_module.permissions"
                                    :key="'perm-' + perm.resource + '-act-' + perm.action"
                                    @click="has_permission(perm) ? remove_permission(perm) : save_permission(perm)">
                                    <td>
                                        <i class="fa fa-check success-update" :class="{ visible: has_permission(perm) }" />
                                    </td>
									<td class="text-left px-3">
                                        <div class="change-status">
                                            <p class="perm-name">{{ perm.name }}</p>
                                            <small>{{ perm.description }}</small>
                                        </div>
                                    </td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal> -->
</template>

<script>
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ProviderProfileService from '@/services/v3/providers/profile.service'
import ClientProfileService from '@/services/v3/clients/profile.service'
import { mapState } from 'vuex'

export default {
  mixins: [loaderMixin],
  props: {
    id: { type: Number },
    type: { type: String },
    owner_name: { type: String },
    closeDialog: { type: Function }
  },
  components: {
    ccPagination: Pagination
  },
  data() {
    return {
      profiles: [],
      permissions: [],
      current_profile: {},
      new_profile: {},
      current_module: {},
      page: 1,
      loading: false,
      loading2: false,
      svc: new ProviderProfileService(),
      actions: [
        { id: "READ", name: "Ler" },
        { id: "CREATE", name: "Criar" },
        { id: "UPDATE", name: "Atualizar" },
        { id: "DELETE", name: "Remover" },
        { id: "READ_ALL", name: "Leitura do Fornecedor" },
        { id: "READ_OTHERS", name: "Leitura Global" },
        { id: "READ_HISTORY", name: "Ler Histórico" },
      ],
      resources: [
        { id: "REQUEST", name: "Cotação" },
        { id: "REQUEST_ORDER", name: "Pedidos" },
        { id: "CLIENT_COMPANY", name: "Empresas" },
        { id: "PROVIDER", name: "Fornecedor" }
      ],
      modal: {
        title: `gerenciar perfis`,
        subtitle: this.owner_name,
        icon_title: 'fas fa-user-lock',
        cancel_text: 'Voltar',
        style: {
          width: "80%"
        }
      },
    };
  },
  methods: {
    has_permission(perm) {
      return this.current_profile.permissions.some(p => p.resource == perm.resource && p.action == perm.action)
    },
    add_permission() {
      this.current_profile.permissions.push(Object.assign(
        this.type == 'PROVIDER' ? { provider_id: this.id } : { client_id: this.id },
        { profile_id: this.current_profile.id }
      ))
    },
    save_permission(permission) {
      this.loading2 = true
      this.svc.save_permission(Object.assign({
        profile_id: this.current_profile.id,
        resource: permission.resource,
        action: permission.action
      }, this.type == 'PROVIDER' ? { provider_id: this.id } : { client_id: this.id },))
        .then(() => this.load())
        .then(() => {
          this.loading2 = false
          const notification = {
            type: 'success',
            message: `Permissão salva com sucesso!`
          }
          this.$store.dispatch('notification/add', notification)
        })
    },
    save(profile) {
      this.present_loader("Salvando perfil...")
      let data = Object.assign(
        this.type == 'PROVIDER' ? { provider_id: this.id } : { client_id: this.id },
        profile
      )
      this.svc.save(data)
        .then((response) => response.data)
        .then(() => this.load())
        .then(this.dismiss_loader).then(() => {
          this.new_profile = {}
          this.$forceUpdate()
        })
    },
    toggle_activation(profile) {
      this.profiles.filter(s => s.id !== profile.id).forEach(s => s.selected = false)
      let g = this.profiles.find(s => s.id == profile.id)
      g.selected = true
      this.current_profile = g
      this.current_profile.permissions.forEach(perm => {
        perm.action_obj = this.actions.find(a => a.id == perm.action)
        perm.resource_obj = this.resources.find(a => a.id == perm.resource)
      })
      this.$forceUpdate()
    },
    select_profile(profile) {
      this.toggle_activation(profile)
    },
    getSpecialBackground(index) {
      if (index % 2 != 0) return 'page-table-line-special'
    },
    remove_profile(profile) {
      this.$set(profile, 'loading', true)
      profile = Object.assign(
        this.type == 'PROVIDER' ? { provider_id: this.id } : { client_id: this.id },
        profile
      )
      return this.svc.remove(profile)
        .then(() => this.load())
        .then(() => this.$set(profile, 'loading', false))
        .then(() => {
          const notification = {
            type: 'success',
            message: 'Removido com sucesso!'
          }
          this.$store.dispatch('notification/add', notification)
        })
    },
    remove_permission(perm) {
      let permission = this.current_profile.permissions.find(p => p.resource == perm.resource && p.action == perm.action)
      permission = Object.assign(
        this.type == 'PROVIDER' ? { provider_id: this.id } : { client_id: this.id },
        permission
      )
      this.loading2 = true
      return this.svc.remove_permission(permission)
        .then(() => this.load())
        .then(() => this.$forceUpdate())
        .then(() => {
          this.loading2 = false
          const notification = {
            type: 'success',
            message: 'Removido com sucesso!'
          }
          this.$store.dispatch('notification/add', notification)
        })
    },
    close() {
      this.$emit("close");
    },
    load() {
      return this.svc.load_profiles(this.id).then(response => response.data).then(data => {
        this.profiles = data.data
        this.$forceUpdate()
        if (this.current_profile.id) {
          let prof = this.profiles.find(p => p.id == this.current_profile.id)
          this.toggle_activation(prof)
        }
      }).catch(error => {
        ErrorHandlerService.handle(error, this.$store);
      });
    },
  },
  computed: {
    ...mapState('permissions', ['modules', 'client_modules']),
    current_modules() {
      return this.type == 'PROVIDER' ? this.modules : this.client_modules
    }
  },
  mounted() {
    if (this.type == 'CLIENT') {
      this.svc = new ClientProfileService()
    }
    this.load()
  }
};
</script>

<style lang="scss" scoped>
@import "./index.modal";

.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}

.modal-content-container {
  background-color: white;
  min-width: 110vw;
  border-radius: 12px;
}

.user-management__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}

.page-table-line-special {
  background: #F7F7F7;
}

.user-management__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 3.5em;
  cursor: pointer;
}

.permission-modal__main-container {
  display: flex;
  justify-content: space-between;
  margin: 2em;
  height: 80vh;
  overflow: auto;
}

.permission-modal__content-container {
  width: 34vw;
  min-height: 60vh;
  border-radius: 8px;
  box-shadow: 0px 0.5124708414077759px 5.12470817565918px 0px #0000001A;
}

.input-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.input-container {
  width: 32vw;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 10px;
  display: flex;
}

.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}

.input-value:focus {
  border: none;
  outline: none;
}

.input-icon {
  color: #ff7110;
  cursor: pointer;
}

.permission-modal__left-container {
  margin: 1em;
}

.permission-modal__middle-container {
  margin: 1em;
}

.permission-modal__right-container {
  margin: 1em;
}

.profiles-wrapper {
  margin: 1em 0em;
}

.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}

.page-table-header-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1.19em;
  color: #505050;
}

.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
}

.page-table-desc-column {
  padding: 0.5em 0.7em;
}

.id-width {
  width: 15%;
}

.input-width {
  width: 55%;
}

.actions-width {
  width: 30%;
}

.remove-icon {
  color: #CD2A2A;
  font-size: 1.7em;
  cursor: pointer;
}

.save-icon {
  color: #ff7110;
  margin-right: 12px;
  cursor: pointer;
  font-size: 1.7em;
}

.edit-icon {
  margin-right: 12px;
  font-size: 1.7em;
  cursor: pointer;
}

.checkbox-width {
  width: 8%;
}

.modules-width {
  width: 92%;
  cursor: pointer;
}

.checkbox-color {
  accent-color: #cc6119;
}

.check-circle-width {
  width: 10%;
  cursor: pointer;
}

.text-width {
  width: 90%;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .permission-modal__main-container {
    flex-direction: column;
    gap: 3vh;
  }

  .permission-modal__content-container {
    width: 100%;
  }

  .modal-mask {
    font-size: 2vw;
  }

  .input-container {
    width: 100%;
  }

  .input-width {
    width: 65%;
  }

  .actions-width {
    width: 20%;
    justify-content: space-between;
    display: flex;
  }
}

@media only screen and (max-width: 450px) {
  .modal-mask {
    font-size: 3vw;
  }

  .input-width {
    width: 55%;
  }

  .actions-width {
    width: 30%;
  }
}
</style>
